/**
 * @description el-input 输入框内容为数字，可限制输入的小数
 * @author George
 * @example <el-input v-model="inputValue" placeholder="请输入数字" v-restrict-number="3"></el-input>
 * @params '限制位数'
 *  3 小数点后三位
 *  默认小数点后两位
 */

const vRestrictNumber = {
    inserted(el, binding, vNode) {
      const decimalPlaces = binding.value || 2; // 获取传入的小数点后位数，默认为2
      el.inputHandler = function (e) {
        let value = e.target.value;
        value = value.replace(/[^\d.]/g, ''); // 只保留数字和小数点

        // 处理连续多个小数点的情况，只保留第一个小数点
        value = value.replace(/(\.\.)+/g, '.');

        // 限制小数点后位数
        if (value.indexOf('.')!== -1) {
          const decimalPart = value.split('.')[1];
          if (decimalPart.length > decimalPlaces) {
            value = value.slice(0, value.indexOf('.') + decimalPlaces + 1);
          }
        }
        // 触发更新
        vNode.componentInstance.$vnode.data.model.value = value            ;
        e.target.value = value;
      };

      el.blurHandler = function (e) {
        let value = e.target.value;
        if (value.endsWith('.')) {
          value = value.slice(0, -1);
          e.target.value = value;
          // 触发更新
          vNode.componentInstance.$vnode.data.model.value = value
        }
      };

      // 获取el-input组件内部的input元素
      const inputElement = el.querySelector('input.el-input__inner');

      // 绑定input事件
      inputElement.addEventListener('input', el.inputHandler);

      // 绑定blur事件
      inputElement.addEventListener('blur', el.blurHandler);
    },
    unbind(el) {
      // 获取el-input组件内部的input元素
      const inputElement = el.querySelector('input.el-input__inner');

      // 移除input事件监听
      inputElement.removeEventListener('input', el.inputHandler);

      // 移除blur事件监听
      inputElement.removeEventListener('blur', el.blurHandler);
    },
  };
export default vRestrictNumber
