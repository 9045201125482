/**
 * @description 批量注册全局指令
 * @author Jeff.Guo
 */

import dialogDrag from './dialog-drag/dialog-drag';
import secret from './secret/secret'; // 隐藏联系方式
import pdfbg from './pdfbg/pdfbg'; // pdfbg定位和瞄点
import auth from './auth/auth'; // 按钮权限控制
import drag from "./drag/drag"; // 拖拽
import restrictNumber from "./restrict-number/restrict-number"; // 拖拽
import positiveInteger from './positiveInteger/positiveInteger'

const directives = {
    dialogDrag,
    secret,
    pdfbg,
    auth,
    drag,
    positiveInteger,
    restrictNumber,
};
// 批量注册指令
export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key]);
        });
    },
};
