import api from './axios';

// 身份证识别（正反面）
export const getIdCard = params => api.get(`/business/baidu-aip/id-card`, {params})
// 往来港澳通行证 （暂时只支持正面）
export const getHkmCard = params => api.get(`/business/baidu-aip/hk-macao-pass`, {params})
// 往来港澳通行证 （暂时只支持正面）
export const getPassort = params => api.get(`/business/baidu-aip/passport`, {params})

// 国家联想
export const getCountries  = (params) => api.get(`/backend/country`, {params})

// 客户端信息获取
export const getClientInfo  = (params) => api.get(`/business/client/information/progress-bar`, {params})

// 客户端信息保存
export const postClientInfo  = (params) => api.post(`/business/client/information/progress-bar`, params)

// 模糊匹配学校全称
export const getSchoolList = (params) => api.get('/client-web/information/schoolList', {params})

// 是否高管
export const postIsExecutives  = (params) => api.post(`/business/client/information/work/is-executives`, params)

// 评分信息
export const getAssessment = (params) => api.get('/business/client/information/assessment', {params})

// 个人信息表 - 所有
export const getDetails = (params) => api.get('/business/client/information/detail', {params})

// 个人信息表 - 所有
export const getDetailsServe = (params) => api.get('/business/information/detail', {params})

// 获取一个key的值
export const getKey = (params) => api.get('/business/order/get-lock', {params})
// 设置一个key
export const setKey  = (params) => api.post(`/business/order/lock`, params)
// 获取名企
// export const getCompanys = (params)=> api.get(`/business/informationSearch/enterprise-list`,{params})
// 企业联想
export const getCompanys = (params)=> api.get(`/business/informationSearch/sw-enterprise-list`,{params})
// 判断企业是否为名企
export const checkCompanies = (params)=> api.get(`/business/informationSearch/enterprise-one`,{params})
// 文件制作-配置清单-删除
export const configurationListDel = params => api.delete(`/business/supplement`, params)
// 获取流程菜单
export const getFlowTree = (params) => api.get(`/backend/order/task/tree`, params)

// 获取专业技能及行业
export const industryList = (params) => api.get(`/business/common-logic/industryList`, params)

// 企业名称搜索
export const getCompanyList = (params)=> api.get(`/business/informationSearch/sw-enterprise-list`,{params})

// 获取公司官网信息
export const getCompanyWebsite = (params)=> api.get(`/business/client/information/companyWebsite`,{params})

// 引流二维码弹窗提醒数据获取
export const getNotifyStatus = (params) => api.get(`/business/client/user/mini-gzh-notify/status`, {params})

// 引流二维码弹窗提醒-暂不提醒
export const postNotifySubmit = params => api.post(`/business/client/user/mini-gzh-notify/submit`, params)

// 完善客户信息-工作情况
export const getWorkSituation = (params)=> api.get(`/business/client-work/get-list`,{params})

// 完善客户信息-工作情况-更新
export const updateWorkSituation = (params)=> api.post(`/business/client-work/edit`, params)

// stem 列表
export const stemList =  (params) => api.get(`/business/client/information/stemList`, {params})
