
import { getHighTalentDetail } from '../../api/highTalent'

const initForm = {
    img_back: "",
    img_front: "",
    issue_at: "",
    issue_date_end_at: "",
    issue_date_start_at: "",
    number: "",
    type: "",
    totalAmount: 0,
}


export default {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem("user")) || {},
        dataList: {}, // 数据集合
        isList: '',
        stepEnd: '',
        totalAmount: 0,
    },

    mutations: {
        updateData(state, data) {
            state.dataList = data
        },

        // 登录用户信息
        updateUser(state, data) {
            state.user = data
        },
    },

    getters: {

    },

    actions: {

        // 服务端编辑保存，刷新状态管理的分数
        async editAction({ commit }, data) {
            try {
                const res = await getHighTalentDetail({ order_id: data.order_id })
                if (res.code === 200) {
                    // 有些数据后端返回null ，前端自己赋默认值

                    if (!res.data.spouse.id) {
                        res.data.spouse = null;
                    }
                    if (res.data.applicant && !res.data.applicant?.certificates?.passport) {
                        res.data.applicant.certificates.passport = JSON.parse(JSON.stringify(initForm))
                    }
                    if (res.data.spouse && !res.data.spouse?.certificates?.passport) {
                        res.data.spouse.certificates.passport = JSON.parse(JSON.stringify(initForm))
                    }
                    if (Array.isArray(res.data.spouse?.birth_place)) {
                        res.data.spouse.birth_place = { area: [], country: '', details: '', foreign: '' }
                    }
                    if (res.data.children) {
                        res.data.children.forEach(element => {
                            if (!element?.certificates?.passport) {
                                element.certificates.passport = JSON.parse(JSON.stringify(initForm))
                            }
                            if (Array.isArray(element.birth_place)) {
                                element.birth_place = { area: [], country: '', details: '', foreign: '' }
                            }
                        });
                    }
                    commit('updateData', res.data)
                }
            } catch (error) {
                console.log(error)
            }
        }

    }
}
